import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import globals from "../components/globals"

import { Grid, ContentBox, H2 } from "../components/styledcomponents"

const Ul = styled.ul`
  text-align: left;
`

const TrainerBox = styled(ContentBox)`
  max-width: ${globals.media.mobil};
  width: 100%;

  @media (min-width: ${globals.media.mobile}) {
    ${(props) =>
    props.gridColumnTablet && `grid-column: ${props.gridColumnTablet}`}
  }

  @media (min-width: ${globals.media.tablet}) {
    ${(props) => props.gridColumn && `grid-column: ${props.gridColumn}`}
  }
`

function Trainer(props) {
  return (
    <TrainerBox
      gridColumn={props.gridColumn}
      gridColumnTablet={props.gridColumnTablet}
    >
      <GatsbyImage
        image={props.img.childImageSharp.gatsbyImageData}
        alt={props.alt}
      />
      {props.children}
    </TrainerBox>
  )
}

const UeberUns = ({ data }) => (
  <Layout>
    <SEO
      title="Über uns"
      description="Wir sind Laurien und Alex, 2 begeisterte Kampfsport- und \
      Yogalehrer. Komm in unsere Schule in München, Feldmoching und lerne uns \
      kennen!"
    />
    <h1>Über Team Laurien & Alex</h1>
    <Grid
      columns="repeat(3, 1fr)"
      columnsTablet="repeat(2, 1fr)"
      gridGap="2em"
      padding="0 2em"
    >
      <Trainer img={data.laurien} alt="Laurien - Headcoach">
        <H2>Laurien - Headcoach</H2>
        <Ul>
          <li>BJJ Purple Belt</li>
          <li>Aktive BJJ Wettkämpferin</li>
          <li>
            Ausgewählte Erfolge:
            <Ul>
              <li>2016 Rome Open Bronze (Gi -53.5kg)</li>
              <li>2017 Munich Open Bronze (Gi -58kg)</li>
              <li>2018 Munich Open Gold (NoGi -56kg) & Silber (Gi -58kg)</li>
              <li>2018 Copa di Ju Silber (NoGi - 56kg) Bronze (Gi -58kg)</li>
              <li>
                2019 AGC Silver (Gi -69kg), 2x Bronze (NoGi -69kg + Gi Absolute)
              </li>
              <li>2023 International Veluwe Open Jiu Jitsu Championship Gi Silber (open weight)</li>
            </Ul>
          </li>
          <li>
            (Kinder) Yoga Lehrerin (ausgebildet in Indien durch Lalit Kumar,
            Himalaya Yoga Valley)
          </li>
          <li>Ausgebildet im Trauma-informierten Unterrichten</li>
          <li>Historikerin</li>
        </Ul>
      </Trainer>
      <Trainer img={data.alex} alt="Alex - Headcoach">
        <H2>Alex - Headcoach</H2>
        <Ul>
          <li>BJJ Purple Belt</li>
          <li>Aktiver MMA / K1 / BJJ Kämpfer</li>
          <li>
            Ausgewählte Erfolge:
            <Ul>
              <li>2016 Munich Open Bronze (Gi -82.3kg)</li>
              <li>2017 Munich Open Bronze (Gi -82.3kg)</li>
              <li>2018 Copa di Ju Silber (NoGi)</li>
              <li>
                2019 ISKA Munich 2x Silver (Sparring & Full Contact -78kg)
              </li>
              <li>2020 MMA Süddeutsche Amateur Shooto 4. Platz (-77.1kg)</li>
              <li>2023 Grappiling Industries Munich NoGi Silber (-185 lbs)</li>
              <li>2023 International Veluwe Open Jiu Jitsu Championship Gi Silber (-82.3 kg)</li>
            </Ul>
          </li>
          <li>2. Dan Ju-Jutsu</li>
          <li>
            (Kinder) Yoga Lehrer (ausgebildet in Indien durch Lalit Kumar,
            Himalaya Yoga Valley)
          </li>
          <li>Ausgebildet im Trauma-informierten Unterrichten</li>
          <li>Elektroingenieur</li>
        </Ul>
      </Trainer>
      <Trainer img={data.yuki} alt="Yuki - Therapiehündin">
        <H2>Yuki - Therapiehündin</H2>
        <Ul>
          <li>Therapiehündin in Ausbildung</li>
          <li>Englischer Springer Spaniel</li>
          <li>Geboren am 12.05.2020</li>
          <li>Immer gut gelaunt</li>
        </Ul>
      </Trainer>
      <Trainer img={data.benni} alt="Benjamin - Assistant Coach">
        <H2>Benjamin - Assistant Coach</H2>
        <Ul>
          <li>BJJ Purple Belt</li>
          <li>(Kids) BJJ & MMA Coach</li>
          <li>Aktiver BJJ Wettkämpfer</li>
          <li>
            Ausgewählte Erfolge:
            <Ul>
              <li>2018 BJJBD Deutsche Meisterschaft 2x Gold (Gi & NoGi)</li>
            </Ul>
          </li>
          <li>Kampfsport & Yoga Fotograf</li>
        </Ul>
      </Trainer>
      <Trainer img={data.sanja} alt="Sanja - Assistant Coach">
        <H2>Sanja - Assistant Coach</H2>
        <Ul>
          <li>BJJ Blue Belt</li>
          <li>(Kids) BJJ & MMA Coach</li>
          <li>Aktive BJJ Wettkämpferin</li>
          <li>
            Ausgewählte Erfolge:
            <Ul>
              <li>2017 BJJBD Deutsche Meisterschaft Gold</li>
              <li>2018 BJJBD Deutsche Meisterschaft Gold</li>
              <li>2019 Submission Only BGC Silber (Nogi -62kg)</li>
              <li>2019 Bavarian Battle Trials Silber (Gi -56.5kg)</li>
              <li>2019 AGC 2x Gold (Gi - 56.5kg + NoGi -54kg)</li>
            </Ul>
          </li>
        </Ul>
      </Trainer>
    </Grid>
  </Layout>
)

export default UeberUns

export const query = graphql`
  {
    laurien: file(relativePath: { eq: "Coaches/Coach-Laurien.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    alex: file(relativePath: { eq: "Coaches/Coach-Alex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    yuki: file(relativePath: { eq: "Coaches/Coach-Yuki.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    sarah: file(relativePath: { eq: "Coaches/Coach-Sarah.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    benni: file(relativePath: { eq: "Coaches/Coach-Benni.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    sanja: file(relativePath: { eq: "Coaches/Coach-Sanja.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
